<template>
  <div style="width: 100%">
    <!--     第一栏-->
    <div class="div_a" style="">
      <!-- <img :src="black" class="div_a_a" style="width: 100%" @load="loadImage"> -->

      <img :src="wz" class="div_a_a" style="width: 100%" @load="loadImage">

      <img :src="mobile_xiazai_button" class="div_a_c" id="text_down" @click="down">

    </div>
    <div v-show="show">
      <!--    第二栏-->
      <div class="div_b" style="">
        <img :src="imageUrl_2" class="div_b_a" style="">
        <div class="div_b_b"
             style="">
          <div class="div_b_b_a" style="">
            <img :src="title_1" width="30%"></div>
          <div class="div_b_b_b" style="">
            <div v-cloak class="div_b_b_b_a" style="">
              <div class="div_b_b_b_a_a"
                   style=";">
                   我们的应用支持公历、农历和甲子历，
                真太阳时精准排盘，涵盖年月日时四柱、 
                胎元、命宫、身宫、大运、流年、流日等多个方面
                。不仅如此，还包括十二长 生、全面的神煞、纳音、人元司令分野等运算，为您提供更为全面、精准的命理信息。
              </div>
            </div>
          </div>
          
        </div>
        <div class="div_bblog" style="">
            <img :src="bblog" style="width: .5rem;">
          </div>
      </div>
      
      <!--    第三栏-->
      <div class="div_c" style="">
        <img :src="imageUrl_3" width="75%">
      </div>
      <!--    第四栏-->
      <div class="div_b" style="">
        <img :src="imageUrl_2" class="div_b_a" style="">
        <div class="div_b_b" style="">
          <div class="div_b_b_a" style=""><img :src="title_2" width="30%"></div>
          <div class="div_b_b_b" style="width: 80%">
            <div v-cloak class="div_b_b_b_a" style="">
              <div
                style=";font-size: 0.288rem;font-family: PingFang SC, PingFang SC;font-weight: 400;">
                我们的平台提供多种分类实战断法，配以详细真实的案例，帮助您深入理解命
                理学的精髓。知识库涵盖八字命理、巾箱秘术、八字实战、易经推命、面相解
                读等多个主题，让您掌握实用技巧和宝贵经验，提升专业水平。通过实战案例
                学习，让您的命理解读更加独到和准确。
              </div>
            </div>
          </div>


        </div>
        <div class="div_bblog" style="">
            <img :src="bblog" style="width: .5rem;">
        </div>
      </div>
      <!--    第五栏-->
      <div style="display: flex;justify-content: center;align-items: center;">
        <img :src="imageUrl_4" width="75%">
      </div>
      <!--    第六栏-->
      <div class="div_b" style="">
        <img :src="imageUrl_2" style="display: inline-block;width: 100%;">
        <div class="div_b_b" style="">
          <div class="div_b_b_a" style=""><img :src="title_3" width="30%"></div>
          <div class="div_b_b_b" style="width: 80%">
            <div v-cloak class="div_b_b_b_a" style="">
              <div
                style=";font-size: 0.288rem;font-family: PingFang SC, PingFang SC;font-weight: 400;">
                六爻排盘为您提供全面、精准的卦象分析。平台支持多种起卦方式，包括手动
                起卦、摇卦等，帮助您灵活选择最适合的方法。主要功能包括详细排盘，显示
                六爻全盘信息，包含主卦、变卦、互卦和错卦；自动解析，提供每一爻的详细
                解释，帮助您理解卦象内涵；神煞分析，结合神煞，提供更深层次的吉凶解读；
                六亲分析，分析卦中的六亲关系，提供全面的家庭、事业、人际关系解读；动
                爻分析，识别并解释动爻，揭示事件的变化和发展趋势；
              </div>
            </div>
          </div>
        </div>
        <div class="div_bblog" style="">
            <img :src="bblog" style="width: .5rem;">
        </div>
      </div>
      <!--    第七栏-->
      <div style="display: flex;justify-content: center;align-items: center;">
        <img :src="imageUrl_5" width="75%">
      </div>
      <!--    第八栏-->
      <div class="div_b" style="">
        <img :src="imageUrl_2" class="div_b_a" style="">
        <div class="div_b_b" style="">
          <div class="div_b_b_a" style=""><img :src="title_4" width="30%"></div>
          <div class="div_b_b_b" style="width: 80%">
            <div v-cloak class="div_b_b_b_a" style="">
              <div
                style=";font-size: 0.288rem;font-family: PingFang SC, PingFang SC;font-weight: 400;">
                1V1咨询是一项个性化服务，用户可以通过平台下单选择特定的老师进行咨询。
                一旦下单，用户即可与所选老师进行在线沟通，提供个人信息后，老师将根据
                用户提供的八字信息进行精准测算，并提供专业的命理解读和建议。这项功能
                为用户提供了与专业命理师一对一的交流机会，帮助用户解答疑惑、指导人生
                方向，为个人发展和决策提供有针对性的指导。
              </div>
            </div>
          </div>


        </div>
        <div class="div_bblog" style="">
            <img :src="bblog" style="width: .5rem;">
        </div>
      </div>
      <!--    第七栏-->
      <div style="display: flex;justify-content: center;align-items: center;">
        <img :src="imageUrl_6" width="75%">
      </div>
      <!--    第八栏-->
      <div class="div_b" style="">
        <img :src="imageUrl_2" class="div_b_a" style="">
        <div class="div_b_b" style="">
          <div class="div_b_b_a" style=""><img :src="title_5" width="30%"></div>
          <div class="div_b_b_b" style="width: 80%">
            <div v-cloak class="div_b_b_b_a" style="">
              <div
                style=";font-size: 0.288rem;font-family: PingFang SC, PingFang SC;font-weight: 400;">
                卜卜乾坤中的工具提供了30多种不同的测算功能，涵盖了命理学中的各个方面。
                无论是八字排盘、风水测算、姓名学分析还是财运预测，您都可以在这个平台
                上找到所需的工具。这些工具不仅可以帮助您深入了解个人命理特点，还能够
                提供实用的建议和指导，助力您在人生道路上做出更明智的选择。
              </div>
            </div>
          </div>


        </div>
        <div class="div_bblog" style="">
            <img :src="bblog" style="width: .5rem;">
        </div>
      </div>
      <!--    第九栏-->
      <div style="display: flex;justify-content: center;align-items: center;">
        <img :src="imageUrl_7" width="75%">
      </div>
      <!--    第13栏-->
      <div style="width: 100%;background-color: #fafafa;display: flex;justify-content: center;align-items: center;">
        <div style="width: 74%">
          <div style="width: 100%;float: left;padding-top: 41px">
            <div style="display: flex;margin-bottom: 31px">
              <img :src="icon_1" width="5%">
              <div
                style="display: flex;justify-content: center;align-items: center;font-family: PingFang SC, PingFang SC;font-weight: bold;font-size: 22px;color: #000000;margin-left: 5px;">
                卜卜乾坤
              </div>
            </div>
            <div style="width: 100%">
              <div
                style="width: 65%;margin-bottom: 0.12rem;font-family: PingFang SC, PingFang SC;color: #000000;display: inline-block;font-size: 0.192rem">
                © Copyright by 2023-{{ endTime }} bububz.com
              </div>
              <div
                style="width: 35%;margin-bottom: 0.12rem;font-family: PingFang SC, PingFang SC;color: #000000;display: inline-block;font-size: 0.192rem">
                邮箱：help@{{ name }}.com
              </div>
            </div>

            <div style="width: 100%;margin-bottom: 0.32rem">
              <div
                style="width: 65%;margin-bottom: 0.22rem;font-family: PingFang SC, PingFang SC;color: #000000;display: inline-block;font-size: 0.192rem">
                版权所有 北京玄易无限网络科技有限公司
              </div>
              <div
                style="width: 35%;margin-bottom: 0.22rem;font-family: PingFang SC, PingFang SC;color: #000000;display: inline-block;font-size: 0.192rem">
                地址：北京市门头沟区石龙经济开发区平安路5号4幢DY2062
              </div>
            </div>
            <div style="width: 100%">
              <div
                style="display: inline-block;text-decoration-line: underline;color: #000000;;font-size: 0.192rem;cursor:pointer">
                <span @click="goHome(4)">用户服务协议</span>
              </div>
              <div
                style="display: inline-block;text-decoration-line: underline;color: #000000;margin-left: 1rem;font-size: 0.192rem;cursor:pointer">
                <span @click="goHome(5)">隐私保护政策</span>
              </div>
            </div>
            <div style="text-align: center;margin: 0.864rem 0rem 0.32rem 0rem">
              <span style="font-size: 0.192rem;cursor:pointer" @click="goHome(9)">{{ leftName }}</span>
              <img :src="icon_2" style="margin: 0rem 0.32rem;width: 0.24rem">
              <span style="font-size: 0.192rem;cursor:pointer"
                    @click="goHome(10,{recordcode:recordcode})">{{ rightName }}</span>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>

import mixin from '@/mixins/comment'
import moment from 'moment';

export default {
  mixins: [mixin],
  name: 'App',
  components: {},
  data() {
    return {
      endTime: '',
      show: false,
      bblog:require('@/assets/div_1/bb.png'),
      imageUrl: require('@/assets/div_1/background.png'),
      imageUrl_2: require('@/assets/div_1/background_2.png'),
      imageUrl_3: require('@/assets/div_1/background_3.png'),
      imageUrl_4: require('@/assets/div_1/background_4.png'),
      imageUrl_5: require('@/assets/div_1/background_5.png'),
      imageUrl_6: require('@/assets/div_1/background_6.png'),
      imageUrl_7: require('@/assets/div_1/background_7.png'),
      imageUrl_8: require('@/assets/div_1/background_8.png'),
      menuUrl_1: require('@/assets/div_1/menu_1.png'),
      menuUrl_2: require('@/assets/div_1/menu_2.png'),
      menuUrl_3: require('@/assets/div_1/menu_3.png'),
      title_1: require('@/assets/div_1/title_1.png'),
      title_2: require('@/assets/div_1/title_2.png'),
      title_3: require('@/assets/div_1/title_3.png'),
      title_4: require('@/assets/div_1/title_4.png'),
      title_5: require('@/assets/div_1/title_5.png'),
      title_6: require('@/assets/div_1/title_6.png'),
      title_7: require('@/assets/div_1/title_7.png'),
      icon_1: require('@/assets/div_1/AppIcon.png'),
      icon_2: require('@/assets/div_1/icon_2.png'),
      black: require('@/assets/div_1/black.png'),
      wz: require('@/assets/div_1/bg1.png'),
      mobile_xiazai_button: require('@/assets/div_2/mobile_xiazai_button.png'),

    }
  },
  mounted() {
    this.endTime = moment().format('YYYY')
    // let that = this
    /*eslint-disable*/
			// const s = document.createElement('script');
			// s.type = 'text/javascript';
			// s.src = 'https://web.cdn.openinstall.io/openinstall.js';  //这里是openinstall的js

			// s.addEventListener('load', () => {
			// 	var data = OpenInstall.parseUrlParams(); //openinstall.js中提供的工具函数，解析url中的所有查询参数
			// 	// data.uri = `geebook://com.cliff/openpage/` //这个唤起地址是需要安卓跟ios提供的
			// 	new OpenInstall({
			// 		/*appKey必选参数，openinstall平台为每个应用分配的ID,这里是我随便写的*/
			// 		appKey: 'gcbf3p',
			// 		/*可选参数，自定义android平台的apk下载文件名；个别andriod浏览器下载时，中文文件名显示乱码，请慎用中文文件名！*/
			// 		//apkFileName : 'com.fm.openinstalldemo-v2.2.0.apk',
			// 		/*可选参数，是否优先考虑拉起app，以牺牲下载体验为代价*/
			// 		//preferWakeup:true,
			// 		/*自定义遮罩的html*/
			// 		//mask:function(){
			// 		//  return "<div id='openinstall_shadow' style='position:fixed;left:0;top:0;background:rgba(0,255,0,0.5);filter:alpha(opacity=50);width:100%;height:100%;z-index:10000;'></div>"
			// 		//},
			// 		/*openinstall初始化完成的回调函数，可选*/
			// 		onready: function() {
			// 			var m = this;
			// 			var button = document.getElementById("text_down")
			// 			// button.style.visibility = "visible";

			// 			/*在app已安装的情况尝试拉起app*/
			// 			// m.schemeWakeup({
			// 			// 	data: data,
			// 			// 	channelCode: "test-channelcode"
			// 			// });

			// 			/*用户点击某个按钮时(假定按钮id为downloadButton)，安装app*/
			// 			 button.onclick = function() {
			// 				console.log('点击了')
      //         m.install(); //此方法为scheme、Universal Link唤醒以及引导下载的作用（必须调用且不可额外自行跳转下载）
      //         return false;
			// 			 }
			// 		}
			// 	}, data);
			// }, false)
			// document.head.appendChild(s);
  },
  methods: {
    loadImage() {
      this.show = true
    },
    down(){
      const ua = navigator.userAgent;
      if (!!ua.match(/(iPhone|iPad|iPod|iOS)/)) {
          window.location.href = 'https://www.pgyer.com/SNJKUGjW'
        } else {
          window.location.href = 'https://www.bububz.com/apk/bububazi.apk'
        }
    }
  }
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0; /* 100/31 */;
}

body {
  padding: 0;
  margin: 0; /* 100/31 */;
}

html {
  padding: 0;
  margin: 0; /* 100/31 */;
}

html {
  width: 100%;
}

.div_a {
  position: relative;

  .div_a_a {
    display: inline-block
  }

  .div_a_b {
    display: inline-block;
    position: absolute;
    top: 15%;
    left: 25%
  }

  .div_a_c {
    position: absolute;
    width: 15%;
    top: 72%;
    left: 50%;
    transform: translate(-50%, 0);
  }

}

.div_b {
  position: relative;

  .div_b_a {
    display: inline-block;
    width: 100%
  }
  .div_bblog{
    position: absolute;
    width: 100%;
    top: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .div_b_b {
    width: 50%;
    height: 80%;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .div_b_b_a {
      width: 20%;
      text-align: center;
      /* padding-top: 50px; */
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .div_b_b_b {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      /* margin-top: 4.2em; */

      .div_b_b_b_a {
        text-transform: none;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        .div_b_b_b_a_a {
          font-size: 0.288rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          /* line-height: 0.512rem; */
        }

        .div_b_b_b_a_b {
          color: #AEAEAE;
          font-weight: 400;
          font-size: 0.192rem;
          line-height: 0.288rem;
          margin-top: 0.3rem;
        }
      }
    }
  }
}

.div_c {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
