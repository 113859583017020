<template>
  <div style="width:100%">
    <div style="width: 100%;height: 2.88rem;background-color: white;position: relative">
      <div
        style="position: absolute;top:0.768rem;left: 0.64rem;font-family: PingFang SC, PingFang SC;font-weight: bold;font-size: 0.96rem;color: #040407;">
        玄易无限
      </div>
      <!-- <img v-show="isHome()" :src="menu" alt="" style="width: 1.2672rem;position: absolute;top:0.8rem;right: 0.64rem"
           @click="goHome(8)"> -->
    </div>
    <div style="width: 100%;position: relative">
      <img :src="mobile_bg1" width="100%">
      <img :src="mobile_xiazai_button" id="text_down" @click="down" style="position: absolute;top:27.104rem;left: 8.672rem;width: 6.624rem"
           >
    </div>
    <div style="width: 100%" v-for="(item,index) in  list" :key="index">
      <div class="background" style="">
        <div style="height: 3.584rem;display: flex;justify-content: center;align-items: center;margin-top: 2rem;">
        <img :src="item.img" alt="" style="width: 7.11008rem">
        </div>
        <div
        style="margin: 0rem 1.344rem;font-family: PingFang SC, PingFang SC;font-weight: 400;font-size: 0.736rem;line-height: 1.5rem;color: #000000;">
        {{item.text}}
      </div>
      <div class="div_bblog" style="">
            <img :src="bblog" style="width: 1rem;">
      </div>
    </div>
      <img :src="item.img1" style="width: 100%;">
    </div>


    <div style="width: 100%">
      <div style="position: relative">
        <bottom></bottom>

      </div>


    </div>
  </div>
</template>
<script>
import mixin from '@/mixins/comment'
import moment from "moment/moment";
import bottom from '@/components/bottom.vue'

export default {
  mixins: [mixin],
  name: 'downloadMobile.vue',
  components: {bottom},
  data() {
    return {
      list:[{
        img:require('@/assets/div_2/text_1.png'),
        img1:require('@/assets/div_2/mobile_bg2.png'),
        text:"我们的应用支持公历、农历和甲子历，真太阳时精准排盘，涵盖年月日时四柱、胎元、命宫、身宫、大运、流年、流日等多个方面。不仅如此，还包括十二长生、全面的神煞、纳音、人元司令分野等运算，为您提供更为全面、精准的命理信息。"
      },{
        img:require('@/assets/div_2/text_2.png'),
        img1:require('@/assets/div_2/mobile_bg3.png'),
        text:"我们的平台提供多种分类实战断法，配以详细真实的案例，帮助您深入理解命理学的精髓。知识库涵盖八字命理、巾箱秘术、八字实战、易经推命、面相解读等多个主题，让您掌握实用技巧和宝贵经验，提升专业水平。通过实战案例学习，让您的命理解读更加独到和准确。"
      },{
        img:require('@/assets/div_2/text_3.png'),
        img1:require('@/assets/div_2/mobile_bg4.png'),
        text:"六爻排盘为您提供全面、精准的卦象分析。平台支持多种起卦方式，包括手动起卦、摇卦等，帮助您灵活选择最适合的方法。主要功能包括详细排盘，显示六爻全盘信息，包含主卦、变卦、互卦和错卦；自动解析，提供每一爻的详细解释，帮助您理解卦象内涵；神煞分析，结合神煞，提供更深层次的吉凶解读；六亲分析，分析卦中的六亲关系，提供全面的家庭、事业、人际关系解读；动爻分析，识别并解释动爻，揭示事件的变化和发展趋势；"
      },{
        img:require('@/assets/div_2/text_4.png'),
        img1:require('@/assets/div_2/mobile_bg5.png'),
        text:"1V1咨询是一项个性化服务，用户可以通过平台下单选择特定的老师进行咨询。一旦下单，用户即可与所选老师进行在线沟通，提供个人信息后，老师将根据用户提供的八字信息进行精准测算，并提供专业的命理解读和建议。这项功能为用户提供了与专业命理师一对一的交流机会，帮助用户解答疑惑、指导人生方向，为个人发展和决策提供有针对性的指导。"
      },{
        img:require('@/assets/div_2/text_5.png'),
        img1:require('@/assets/div_2/mobile_bg6.png'),
        text:"卜卜乾坤中的工具提供了30多种不同的测算功能，涵盖了命理学中的各个方面。无论是八字排盘、风水测算、姓名学分析还是财运预测，您都可以在这个平台上找到所需的工具。这些工具不仅可以帮助您深入了解个人命理特点，还能够提供实用的建议和指导，助力您在人生道路上做出更明智的选择。"
      }],
      endTime: '',
      bblog:require('@/assets/div_1/bb.png'),
      mobile_bg1: require('@/assets/div_2/mobile_bg1.png'),
      mobile_bg2: require('@/assets/div_2/mobile_bg2.png'),
      mobile_bg3: require('@/assets/div_2/mobile_bg3.png'),
      mobile_bg4: require('@/assets/div_2/mobile_bg4.png'),
      mobile_bg5: require('@/assets/div_2/mobile_bg5.png'),
      mobile_bg6: require('@/assets/div_2/mobile_bg6.png'),
      mobile_bg7: require('@/assets/div_2/mobile_bg7.png'),
      mobile_bg8: require('@/assets/div_2/mobile_bg8.png'),
      mobile_bg9: require('@/assets/div_2/mobile_bg9.png'),
      mobile_xiazai_button: require('@/assets/div_2/mobile_xiazai_button.png'),
      text_1: require('@/assets/div_2/text_1.png'),
      text_2: require('@/assets/div_2/text_2.png'),
      text_3: require('@/assets/div_2/text_3.png'),
      text_4: require('@/assets/div_2/text_4.png'),
      text_5: require('@/assets/div_2/text_5.png'),
      text_6: require('@/assets/div_2/text_6.png'),
      text_7: require('@/assets/div_2/text_7.png'),
      icon_2: require('@/assets/div_1/icon_2.png'),
      back: require('@/assets/div_2/back.png'),
      menu: require('@/assets/div_2/menu.png'),
    }
  },
  methods: {
    isHome() {
      return this.$route.name === '首页'
    }
  },
  created() {
  },
  mounted() {
    this.endTime = moment().format('YYYY')
    // let that = this
    /*eslint-disable*/
			// const s = document.createElement('script');
			// s.type = 'text/javascript';
			// s.src = 'https://web.cdn.openinstall.io/openinstall.js';  //这里是openinstall的js

			// s.addEventListener('load', () => {
			// 	var data = OpenInstall.parseUrlParams(); //openinstall.js中提供的工具函数，解析url中的所有查询参数
			// 	// data.uri = `geebook://com.cliff/openpage/` //这个唤起地址是需要安卓跟ios提供的
			// 	new OpenInstall({
			// 		/*appKey必选参数，openinstall平台为每个应用分配的ID,这里是我随便写的*/
			// 		appKey: 'gcbf3p',
			// 		/*可选参数，自定义android平台的apk下载文件名；个别andriod浏览器下载时，中文文件名显示乱码，请慎用中文文件名！*/
			// 		//apkFileName : 'com.fm.openinstalldemo-v2.2.0.apk',
			// 		/*可选参数，是否优先考虑拉起app，以牺牲下载体验为代价*/
			// 		//preferWakeup:true,
			// 		/*自定义遮罩的html*/
			// 		//mask:function(){
			// 		//  return "<div id='openinstall_shadow' style='position:fixed;left:0;top:0;background:rgba(0,255,0,0.5);filter:alpha(opacity=50);width:100%;height:100%;z-index:10000;'></div>"
			// 		//},
			// 		/*openinstall初始化完成的回调函数，可选*/
			// 		onready: function() {
			// 			var m = this;
			// 			var button = document.getElementById("text_down")
			// 			// button.style.visibility = "visible";

			// 			/*在app已安装的情况尝试拉起app*/
			// 			// m.schemeWakeup({
			// 			// 	data: data,
			// 			// 	channelCode: "test-channelcode"
			// 			// });

			// 			/*用户点击某个按钮时(假定按钮id为downloadButton)，安装app*/
			// 			 button.onclick = function() {
			// 				console.log('点击了')
      //         m.install(); //此方法为scheme、Universal Link唤醒以及引导下载的作用（必须调用且不可额外自行跳转下载）
      //         return false;
			// 			 }
			// 		}
			// 	}, data);
			// }, false)
			// document.head.appendChild(s);
  },
  methods:{
    down(){
      const ua = navigator.userAgent;
      // console.log(ua,'ua')
      if (!!ua.match(/(iPhone|iPad|iPod|iOS)/)) {
          window.location.href = 'https://www.pgyer.com/SNJKUGjW'
        } else {
          window.location.href = 'https://www.bububz.com/apk/bububazi.apk'
        }
    }
  }
}
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
}

img {
  display: block;
  height: auto;
}
.div_bblog{
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 2rem 0 1rem 0;
}
.background{
  background-image:url('/src/assets/div_2/background.png');background-size: 100% 100%;background-repeat: no-repeat;
}
</style>
